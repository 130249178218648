/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckAccessViewsCombinationDto } from '../models/CheckAccessViewsCombinationDto';
import type { CheckUsersInGroupsDto } from '../models/CheckUsersInGroupsDto';
import type { CreateGroupDto } from '../models/CreateGroupDto';
import type { ListUserRequestDto } from '../models/ListUserRequestDto';
import type { UpdateGroupDto } from '../models/UpdateGroupDto';
import { request as __request } from '@openapi/request';

export class EfnaConfigurationService {

    /**
     * Create a new EFNA group
     * @param requestBody
     * @returns any Group created successfully
     * @throws ApiError
     */
    public static async createGroup(
        requestBody: CreateGroupDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efna-configuration/efna-group`,
            body: requestBody,
            errors: {
                400: `Bad request`,
            },
        });
        return result.body;
    }

    /**
     * Get all EFNA groups
     * @returns any Successfully retrieved all groups
     * @throws ApiError
     */
    public static async getAllGroups(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/efna-group/all`,
        });
        return result.body;
    }

    /**
     * Update an EFNA group
     * @param id ID of the group to update
     * @param requestBody
     * @returns any Group updated successfully
     * @throws ApiError
     */
    public static async updateGroup(
        id: string,
        requestBody: UpdateGroupDto,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/web/api/efna-configuration/efna-group/${id}`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                404: `Group not found`,
            },
        });
        return result.body;
    }

    /**
     * Delete an EFNA group
     * @param id ID of the group to delete
     * @returns any Group deleted successfully
     * @throws ApiError
     */
    public static async deleteGroup(
        id: string,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/web/api/efna-configuration/efna-group/${id}`,
            errors: {
                404: `Group not found`,
            },
        });
        return result.body;
    }

    /**
     * Get an EFNA group by ID
     * @param id ID of the group to retrieve
     * @returns any Successfully retrieved the group
     * @throws ApiError
     */
    public static async getGroupById(
        id: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/efna-group/${id}`,
            errors: {
                404: `Group not found`,
            },
        });
        return result.body;
    }

    /**
     * Get user access views
     * @param userOid OID of the user
     * @returns any Successfully retrieved user access views
     * @throws ApiError
     */
    public static async getUserAccessViews(
        userOid: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/efna-group/access-views/${userOid}`,
        });
        return result.body;
    }

    /**
     * Get users with optional filters for system role and fullname
     * @param fullname Filter users by full name
     * @param systemRole Filter users by system role
     * @returns any Successfully retrieved filtered users
     * @throws ApiError
     */
    public static async getUsersWithFilters(
        fullname?: string,
        systemRole?: string,
    ): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/efna-group/users-with-filters`,
            query: {
                'fullname': fullname,
                'systemRole': systemRole,
            },
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static async getUsersList(
        requestBody: ListUserRequestDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efna-configuration/efna-group/users/list`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async efnaCsvExport(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efna-configuration/efna-group/csv-export`,
        });
        return result.body;
    }

    /**
     * Check if users exist in other groups
     * @param requestBody
     * @returns any Users are not in any groups
     * @throws ApiError
     */
    public static async checkUsersInGroups(
        requestBody: CheckUsersInGroupsDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efna-configuration/efna-group/check-users-in-groups`,
            body: requestBody,
            errors: {
                400: `Users found in existing groups`,
            },
        });
        return result.body;
    }

    /**
     * Check if access view combination already exists
     * @param requestBody
     * @returns any Access view combination does not exist
     * @throws ApiError
     */
    public static async checkAccessViewsCombination(
        requestBody: CheckAccessViewsCombinationDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efna-configuration/efna-group/check-access-views-combination`,
            body: requestBody,
            errors: {
                400: `Access view combination already exists`,
            },
        });
        return result.body;
    }

    /**
     * Get all EFNA Access View
     * @returns any Successfully retrieved all access view
     * @throws ApiError
     */
    public static async getAllAccessViews(): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/efna-group/accessViews/list`,
        });
        return result.body;
    }

    /**
     * Get all backend checker options
     * @returns any List of all backend checker options
     * @throws ApiError
     */
    public static async getBackendCheckerOptions(): Promise<Array<{
        oid?: string,
        name?: string,
    }>> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/backend-checker/options`,
        });
        return result.body;
    }

    /**
     * Get the currently selected backend checker
     * @returns any The currently selected backend checker
     * @throws ApiError
     */
    public static async getSelectedBackendChecker(): Promise<{
        oid?: string,
        name?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/web/api/efna-configuration/backend-checker/selected`,
            errors: {
                404: `No backend checker selected`,
            },
        });
        return result.body;
    }

    /**
     * Set the selected backend checker
     * @param requestBody
     * @returns any Backend checker successfully selected
     * @throws ApiError
     */
    public static async setSelectedBackendChecker(
        requestBody: {
            /**
             * OID of the backend checker option to select
             */
            optionOid: string,
        },
    ): Promise<{
        oid?: string,
        name?: string,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/web/api/efna-configuration/backend-checker/selected`,
            body: requestBody,
            errors: {
                400: `Invalid backend checker option`,
            },
        });
        return result.body;
    }

}