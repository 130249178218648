import axios from 'axios'
import {createStore} from 'vuex'
import KYCFormStore from '@/views/kyc-form/KYCFormStore'
import KYCPdfStore from '@/views/kyc-pdf/KYCPdfStore'
import KYCSectionStore from './KYCSectionStore'
import KYCPdfSectionStore from './KYCPdfSectionStore'
import KYCSubmissionStore from './modules/KYCSubmissionStore'
import BscStore from '@core/pages/bsc/store/BscStore'
import ClientInfoStore from '@core/pages/client-management/master-view/ClientInfoStore'
import ComplianceStore from '@core/pages/compliance/store/ComplianceStore'
import ClientPolicyStore from '@core/pages/new-business-register/store/ClientPolicyStore'
import ScheduleStore from '@core/pages/scheduler/store/ScheduleStore'
import UserStore from '@core/pages/user-profile/store/UserStore'
import ClientStore from '@core/store/ClientStore'
import HierarchySetupStore from '@core/pages/hierarchy-setup/store/HierarchySetupStore'
import ProductStore from '@core/store/ProductStore'
import MyinfoStore from '@core/pages/individual-myinfo/MyinfoStore'
import CpdCourseStore from '@core/pages/cpd/store/CpdCourseStore'
import ActivityStore from '@core/pages/activity/ActivityStore'
import RecruitFormStore from '@core/pages/recruit/RecruitFormStore'
import CompensationSetupStore from '@core/pages/compensation-setup/store/CompensationSetupStore'
import AdviserTeamManagementStore from '@core/pages/adviser-team-management/store/AdviserTeamManagementStore'
import UserStoreV2 from '@core/pages/user-profile/store/UserStoreV2'
import ApprovalStore from '@core/pages/approvals/store/ApprovalStore'
import TransactionsStore from '@core/pages/commission/store/TransactionsStore'
import BatchRecordsStore from '@core/pages/commission/store/BatchRecordsStore'
import CommissionsStore from '@core/pages/commission/store/CommissionsStore'
import IntermediariesStore from '@core/pages/commission/store/IntermediariesStore'
import OtherIncomeStore from '@core/pages/commission/store/OtherIncomeStore'
import BranchManagerStore from '@core/pages/branch-manager/store/BranchManagerStore'
import StatementGeneratorStore from '@core/pages/commission/store/StatementGeneratorStore'
import CommissionStatementStore from '@core/pages/commission/store/CommissionStatementStore'
import AuditLogStore from '@core/pages/auditLog/store/AuditLogStore'
import ExportedStore from '@core/pages/commission/store/ExportedStore'
import DataLoadStore from '@core/pages/data-load/store/DataLoadStore'
import GroupStore from '@core/pages/groups/store/GroupStore'
import BscSetupStore from '@core/pages/bsc-setup/store/BscSetupStore'
import BscGradingStore from '@core/pages/bsc-grading/store/bscGradingStore'
import ESuppStore from '@core/pages/efna-dashboard/store/ESuppStore'
import FileUploads from '@core/pages/efna-dashboard/store/FileUploads'

import {TenantConfigurationNativeService} from '@core/openapi/services/tenant-configuration/api/services/TenantConfigurationNativeService'
// Vue.use(Vuex) -- not required in vue3

// const store = createStore

// export default new Vuex.Store({
const store = createStore({
  state: {
    user: {},
    permission: [],
    allPermission: [],
    isAdminUser: false,
    IPConfigData: {},
    logo: {},
  },
  getters: {
    currentUser (state) {
      return state.user
    },
    currentPermission (state) {
      return state.permission
    },
    currentAllPermission (state) {
      return state.allPermission
    },
    isCurrentAdminUser (state) {
      return state.isAdminUser
    },
    IPConfigData (state) {
      return state.IPConfigData
    },
    logoData (state) {
      return state.logo
    }
  },
  mutations: {
    setCurrentUser (state, user) {
      if (user == null) {
        state.user = {}
      } else {
        Object.assign(state.user, user)
      }
    },
    setCurrentAdminUser (state, user) {
      state.isAdminUser = !['adviser', 'supervisor', 'director'].includes(user.systemRole)
    },
    setPermission (state, permission) {
      state.permission = permission
    },
    setAllPermission (state, allPermission) {
      state.allPermission = allPermission
    },
    setCurrentUserIPConfigData(state, IPConfigData) {
      state.IPConfigData = IPConfigData
    },
    setLogo (state, logo) {
     //console.log(logo)
      state.logo = logo;
      localStorage.setItem('logo', logo)
      localStorage.setItem('imageType', 'svg')
     
    },
    async setLogoOthers (state, logo) {
      state.logo = logo;
      localStorage.setItem('logo', logo)
      localStorage.setItem('imageType', 'others')
    }
  },
  actions: {
    login (context, user) {
      context.commit('setCurrentUser', user)
      context.commit('setCurrentAdminUser', user)
      const url = 'http://ipinfo.io/json'

      axios.get(url).then(response => {
        context.commit('setCurrentUserIPConfigData', response.data)
      })
    },
    logout (context) {
      context.commit('setCurrentUser', null)
    },
    getPermission (context, permission) {
      context.commit('setPermission', permission)
    },
    getAllPermission (context, allPermission) {
      context.commit('setAllPermission', allPermission)
    },
    async retrieveLogo(context) {
      const res = await TenantConfigurationNativeService.getCurrentTenantLogo()
      const svgRegex = /<svg[\s\S]*?>[\s\S]*<\/svg>/i;
      if (svgRegex.test(res)) {
        context.commit('setLogo', res)
      }
      else {
        context.commit('setLogoOthers', '/web/api/tenant-config/logo')

      }
      console.log(res)

    }
  },
})

store.registerModule('KYCFormStore', KYCFormStore);
store.registerModule('KYCPdfStore', KYCPdfStore);
store.registerModule('KYCSectionStore', KYCSectionStore);
store.registerModule('KYCPdfSectionStore', KYCPdfSectionStore);
store.registerModule('KYCSubmissionStore', KYCSubmissionStore);
store.registerModule('BscStore', BscStore);
store.registerModule('ClientInfoStore', ClientInfoStore);
store.registerModule('ComplianceStore', ComplianceStore);
store.registerModule('ClientPolicyStore', ClientPolicyStore);
store.registerModule('ScheduleStore', ScheduleStore);
store.registerModule('UserStore', UserStore);
store.registerModule('ClientStore', ClientStore);
store.registerModule('HierarchySetupStore', HierarchySetupStore);
store.registerModule('ProductStore', ProductStore);
store.registerModule('MyinfoStore', MyinfoStore);
store.registerModule('CpdCourseStore', CpdCourseStore);
store.registerModule('ActivityStore', ActivityStore);
store.registerModule('RecruitFormStore', RecruitFormStore);
store.registerModule('CompensationSetupStore', CompensationSetupStore);
store.registerModule('AdviserTeamManagementStore', AdviserTeamManagementStore);
store.registerModule('UserStoreV2', UserStoreV2);
store.registerModule('ApprovalStore', ApprovalStore);
store.registerModule('BranchManagerStore', BranchManagerStore);
store.registerModule('TransactionsStore', TransactionsStore);
store.registerModule('BatchRecordsStore', BatchRecordsStore);
store.registerModule('CommissionsStore', CommissionsStore);
store.registerModule('IntermediariesStore', IntermediariesStore);
store.registerModule('OtherIncomeStore', OtherIncomeStore);
store.registerModule('StatementGeneratorStore', StatementGeneratorStore);
store.registerModule('CommissionStatementStore', CommissionStatementStore);
store.registerModule('AuditLogStore', AuditLogStore);
store.registerModule('ExportedStore', ExportedStore);
store.registerModule('BscGradingStore', BscGradingStore);
store.registerModule('BscSetupStore', BscSetupStore);
store.registerModule('DataLoadStore', DataLoadStore);
store.registerModule('GroupStore', GroupStore);
store.registerModule('ESuppStore', ESuppStore);
store.registerModule('FileUploads', FileUploads);

export default store
