  import { SharedVariable } from '@core/model/SharedVariable'
import { UIService } from '@core/services/UIService'
import { cid, container } from 'inversify-props'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { BscService } from '@core/pages/bsc/api/services/BscService'
import { BscGradeImportSummaryDto } from '@core/pages/bsc/api/index'
import { BscGradeImportSummary } from '@core/pages/bsc-grading/model/BscGradeImportSummary'
import { AccountService } from '@core/openapi/services/user-account/api'
import router from '@/router'
const sharedVariable = container.get<SharedVariable>(cid.SharedVariable)
const uiService = sharedVariable.uiService ? sharedVariable.uiService : new UIService()
import { DateService } from '@master/services/DateService'
import { toEventObject } from 'xstate'
import UserStoreV2 from '@core/pages/user-profile/store/UserStoreV2'


const dateService = new DateService()

@Module({ namespaced: true, name: 'BscGradingStore' })
export default class BscGradingStore extends VuexModule {
  baseRecordData: any = {}
  baseRecordListData = []
  baseRecordListRecordsTotal = 0
  bscGradeListData = []
  bscGradeListRecordsTotal = 0
  agentFindingListData = []
  agentFindingListRecordsTotal = 0
  policyListData = []
  bscFindingRecord:any = {}
  bscGradeRecord: BscGradeImportSummaryDto = new BscGradeImportSummary()
  cycleExecutionSummaryData = []
  cycleExecutionSummaryRecordsTotal = 0
  advisersReviewData = []
  advisersReviewRecordsTotal = 0
  supervisorReviewData = []
  supervisorReviewRecordsTotal = 0
  activeAdvisers: any[] = []
  baseRecordStatus = false
  listBSCRecords = []
  bscRecordsTotal = 0
  gradingDetails:any = {}
  adviserPolicy = []
  loading = false
  adviserPolicyRecordsTotal = 0

  @Mutation
  setBaseRecordDataResponse ({data}) {
    this.baseRecordData = data
    console.log(this.baseRecordStatus)
    if (data.status === 'Close') {
      this.baseRecordStatus = true
    }
    else {
      this.baseRecordStatus = false
    }
  }

  @Mutation
  setPolicyDetailsData ({data, recordsTotal}) {
    const formattedData = data.map(item => {
      return {
        ...item,
        reviewDate: dateService.format(Number(item.reviewDate), "DD MMM YYYY"),
      };
    });
    this.adviserPolicy = formattedData
    this.adviserPolicyRecordsTotal = recordsTotal
  }

  @Mutation
  setAdvisersList (data) {
    this.activeAdvisers = data
  }

  @Mutation
  setAdvisersReviewResponse ({data, recordsTotal}) {
    this.advisersReviewData = data
    this.advisersReviewRecordsTotal = recordsTotal
  }

  @Mutation
  setSupervisorReviewResponse ({data, recordsTotal}) {
    this.supervisorReviewData = data
    this.supervisorReviewRecordsTotal = recordsTotal
  }

  @Mutation
  setLoading (data) {
    this.loading = data
  }

  @Mutation
  setPreviewFindingRecord (data) {
    this.bscFindingRecord = data
    this.bscFindingRecord.infraction = JSON.parse(data?.infraction)
    // this.bscFindingRecord.infractionArray = JSON.parse(data?.infraction)
  }

  @Mutation
  setGradingDetails (data) {
    this.gradingDetails = data
  }

  @Mutation
  setPreviewBscGradeRecord (data) {
    this.bscGradeRecord = data
  }

  @Mutation
  setBSCRecords ({data, recordsTotal}) {
    this.listBSCRecords = data;
    this.bscRecordsTotal = recordsTotal
  }

  @Mutation
  setCycleExecutionSummaryData ({data, recordsTotal}) {
    const formattedData = data.map(item => {
      return {
        ...item,
        cycleStartDate: dateService.format(Number(item.cycleStartDate), "DD MMM YYYY"),
        cycleEndDate: dateService.format(Number(item.cycleEndDate), "DD MMM YYYY"),
        ranBy: item.ranByDetails.userPersonalInfo.fullname ? item.ranByDetails.userPersonalInfo.fullname : `${item.ranByDetails.userPersonalInfo.firstName} ${item.ranByDetails.userPersonalInfo.lastName} `
      };
    });
    
    this.cycleExecutionSummaryData = formattedData;
    this.cycleExecutionSummaryRecordsTotal = recordsTotal;
  }
  
  @Action({rawError: true})
  async publishGrades (payload) {
    return await BscService.publishAdviserSupervisorIncomes(payload)
  }

  @Action({rawError: true})
  async changeEvent ({oid, event}) {
    console.log(event)
    const payload = {
      status: event
    }
    return await BscService.updateBscBaseRecord(oid, payload)
  }

  @Action({rawError: true})
  async fetchActiveAdviserUserList() {
    try {
      if(this.activeAdvisers.length <1){
        const activeAdviserUsers = await AccountService.getAdviserDropdown()
        this.context.commit("setActiveAdviserUserList", activeAdviserUsers.data)
        
      }
      return this.activeAdvisers
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({rawError: true})
  async listUserBscRecords ({limit, page, adviserCode}) {
    try {
      const res = await BscService.getAdvisersPublishedGrades(adviserCode, {limit, page})
      console.log(res)
      if (res.data.length > 0) {
        const mappedRes = res.data.map(item => {
          console.log(item)
          return {
            ...item,
            BASE_RECORD_OID: res.data[0].BASE_RECORD_OID,
            adviserName: UserStoreV2.state.currentUserinViewRawResponse?.userPersonalInfo?.fullname,
            adviserCode: UserStoreV2.state.currentUserinViewRawResponse?.userCompanyInfo?.companyCode,
            BSC_GRADE: (item.BSC_GRADE === '' || item.BSC_GRADE === null) ? "Ungraded" : item.BSC_GRADE,
            LAST_BSC_GRADE: (item.LAST_BSC_GRADE === '' || item.LAST_BSC_GRADE === null) ? "Ungraded" : item.LAST_BSC_GRADE,
            CAT1_FREQUENCY: (item.CAT1_FREQUENCY === '' || item.CAT1_FREQUENCY === null) ? 0 : item.CAT1_FREQUENCY,
            CAT2_FREQUENCY: (item.CAT2_FREQUENCY === '' || item.CAT2_FREQUENCY === null) ? 0 : item.CAT2_FREQUENCY,
            ADMIN_LAPSES_FREQUENCY: (item.ADMIN_LAPSES_FREQUENCY === '' || item.ADMIN_LAPSES_FREQUENCY === null) ? 0 : item.ADMIN_LAPSES_FREQUENCY,
            GROSS_VAR_INC: (item.GROSS_VAR_INC === '' || item.GROSS_VAR_INC === null) ? 0 : item.GROSS_VAR_INC,
            SPECIFIED_VAR_INC: (item.SPECIFIED_VAR_INC === '' || item.SPECIFIED_VAR_INC === null) ? 0 : item.SPECIFIED_VAR_INC,
            ENTITLED_VAR_INC: (item.ENTITLED_VAR_INC === '' || item.ENTITLED_VAR_INC === null) ? 0 : item.ENTITLED_VAR_INC,
            CLAWBACK_PRCT: (item.CLAWBACK_PRCT === '' || item.CLAWBACK_PRCT === null) ? 0 : item.CLAWBACK_PRCT,
            CLAWBACK_AMT: (item.CLAWBACK_AMT === '' || item.CLAWBACK_AMT === null) ? 0 : item.CLAWBACK_AMT,
          }
        })
        console.log(mappedRes)
        this.context.commit("setBSCRecords", {data: mappedRes, recordsTotal: res.recordsTotal})
      }
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({rawError: true})
  async dataFetch ({limit, page, adviserCode, bscRecordOid}) {
    try {
      const res = await BscService.listAdvisersPublishedIncomes( bscRecordOid,adviserCode, {limit, page})
      
      this.context.commit("setGradingDetails", res)
      console.log(res)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({rawError: true})
  async getAdviserPolicyDetails({bscRecordOid, adviserCode, currentPage, limit}) {
    try {
      const res = await BscService.getBscAgentFindingsByAdviserCode( adviserCode, bscRecordOid, {limit, page: currentPage})
      this.context.commit("setPolicyDetailsData", res)
      console.log(res)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error
      })
    }
  }

  @Action({rawError: true})
  async getBscAgentFindingPreview (bscBaseRecordOid) {
    const response = await BscService.getBscAgentFindingByOid(bscBaseRecordOid)

    this.context.commit('setPreviewFindingRecord', response)
  }

  @Action({rawError: true})
  async triggerSupervisorExport ({format, retrieveAll, baseRecordOid}) {
    return await BscService.exportSupervisorIncomes(format, baseRecordOid, {retrieveAll: true})
  }

  @Action({rawError: true})
  async triggerExport ({format, retrieveAll, baseRecordOid}) {
    return await BscService.exportAdviserIncomes(format, baseRecordOid, {retrieveAll: true})
  }

  @Action ({ rawError: true})
  async updateFindingsRecord ({oid, data}) {
    const res = await BscService.updateBscAgentFinding(oid, data)
  }
  
  @Action ({ rawError: true})
  async updateBscGradeImportSummary ({oid, data}) {
    const res = await BscService.updateBscGradeImportSummary(oid, data)
  }
  
  @Action ({ rawError: true})
  async runExecution (oid) {
    const res = await BscService.runCycleExecution(oid)
  }

  @Action ({ rawError: true})
  async fetchAdviserReview ({limit, page, search, baseRecordOid}) {
    this.context.commit('setLoading', true)
    const res = await BscService.retrieveAdviserIncomes(baseRecordOid, {limit, page, search})
    if (res) {
      this.context.commit('setLoading', false)
      this.context.commit('setAdvisersReviewResponse', res)
    }
  }

  @Action ({ rawError: true})
  async fetchSuperviserReview ({limit, page, search, baseRecordOid}) {
    this.context.commit('setLoading', true)
    const res = await BscService.retrieveSupervisorIncomes(baseRecordOid, {limit, page, search})
    if (res) {
      this.context.commit('setLoading', false)
      this.context.commit('setSupervisorReviewResponse', res)
    }
  }

  @Action({rawError: true})
  async getBscGradePreview (bscBaseRecordOid) {
    const response = await BscService.getBscGradeImportSummaryByOid(bscBaseRecordOid)

    this.context.commit('setPreviewBscGradeRecord', response)
  }
  @Action({rawError: true})
  async fetchBaseRecordData (oid: string) {
    try {
      const response = await BscService.getBscBaseRecordByOid(oid)
      this.context.commit('setBaseRecordDataResponse', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error.message
      })
      // await router.push({
      //   name: 'bsc-grading-list',
      // });
    }
  }

  @Action({rawError: true})
  async fetchPreviewRecord (oid: string) {
    try {
      // const response = await BscService.getBscBaseRecordByOid(oid)
      // this.context.commit('setBaseRecordDataResponse', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error.message
      })
      // await router.push({
      //   name: 'bsc-grading-list',
      // });
    }
  }

  @Mutation
  setBaseRecordResponse ({data, recordsTotal}) {
    data.map(item => {
      item.reviewQuarter = `Q${item.reviewQuarter}`
  })
    this.baseRecordListData = data
    this.baseRecordListRecordsTotal = recordsTotal
  }

  @Action({rawError: true})
  async fetchBaseRecordList ({ limit, page }) {
    const response = await BscService.retrieveListOfbscBaseRecord({ limit, page })

    this.context.commit('setBaseRecordResponse', response)
  }

  @Mutation
  setBscGradeRecordResponse ({data, recordsTotal}) {
    this.bscGradeListData = data
    this.bscGradeListRecordsTotal = recordsTotal
  }

  @Action({rawError: true})
  async fetchBscGradeList ({bscBaseRecordOid, limit, page, retrieveAll}) {
    const response = await BscService.retrieveListOfBscGradeImportSummary(bscBaseRecordOid, limit, page, retrieveAll)

    this.context.commit('setBscGradeRecordResponse', response)
  }

  @Mutation
  setPolicyList (data) {
    this.policyListData = data
  }

  @Action({rawError: true})
  async fetchPolicyList () {
    const response = await BscService.getBscAgentFindingPolicyList()

    this.context.commit('setPolicyList', response)
  }

  @Action({rawError: true})
  async saveBscGrade (payload) {
    await BscService.createBscGradeImportSummary(payload)
  }

  @Action({rawError: true})
  async deleteBscGradeRecord (oids) {
    const response = await BscService.deleteBscGradeImportSummary(oids)
  }

  @Mutation
  setAgentFindingResponse ({data, recordsTotal}) {
    this.agentFindingListData = data
    this.agentFindingListRecordsTotal = recordsTotal
  }

  @Action({rawError: true})
  async fetchAgentFindingList (bscBaseRecordOid, body) {
    const response = await BscService.retrieveListOfbscAgentFinding(bscBaseRecordOid, body)

    this.context.commit('setAgentFindingResponse', response)
  }

  @Action({rawError: true})
  async saveAgentFinding (payload) {
    await BscService.createBscAgentFinding(payload)
  }

  @Action({rawError: true})
  async deleteAgentFinding (oids) {
    const response = await BscService.deleteAgentFinding(oids)
  }

  @Action({rawError: true})
  async saveBaseRecord (payload) {
    return await BscService.createBscBaseRecord(payload)
  }

  @Action({rawError: true})
  async updateVariablePercentage ({oid, payload }) {
    // const response = await BscService.updateBscVariablePercentage(oid, payload)
    // console.log(response)

    // this.context.commit('setVariableResponse', response)
  }

  @Action({rawError: true})
  async fetchCycleExecutionSummaryData ({limit, page}) {
    try {
      const response = await BscService.retrieveBscCycleExecutionList({limit, page})
      this.context.commit('setCycleExecutionSummaryData', response)
    } catch (error) {
      uiService.alert.show({
        type: 'error',
        message: error.message
      })
      await router.push({
        name: 'bsc-grading',
      });
    }
  }

  @Action({rawError: true})
  async importAgentFinding (data) {
    return await BscService.importBscAgentFinding()
  }

  @Action({rawError: true})
  async importBscGradeImportSummary (data) {
    return await BscService.importBscGradeImportSummary()
  }
}
