import { RouteRecordRaw } from 'vue-router'
import { LOB_VALUES } from '@core/utils/global.json'

export const newBusinessRegisterRouter = ((): RouteRecordRaw[] => {
  return [
    {
      path: '/policy-list',
      name: 'Client Policies List',
      component: () => import('@core/pages/new-business-register/components/policy/page/ClientPolicyList.vue'),
      meta: {
        subject: ['client', 'clientPolicyV2', 'clientProduct', 'company', 'policyIndividual']
      }
    },
    {
      path: '/policy/:policyOid',
      name: 'Policy',
      component: () => import('@/views/new-business-register/NewBusinessRegister.vue'),
      meta: {
        subject: ['client', 'clientPolicyV2', 'clientProduct', 'company', 'policyIndividual'], 
        showSaveButton: false
      },
      redirect: { name: 'Policy Overview' },
      children: [
        {
          path: 'overview',
          name: 'Policy Overview',
          component: () => import('@core/pages/new-business-register/components/policy/page/NBROverviewPage.vue')
        },
        {
          path: 'detail',
          name: 'Policy Details',
          component: () => import('@core/components/PassThrough.vue'),
          redirect: { name: 'NBR Details' },
          children: [
            {
              path: 'nbr',
              name: 'NBR Details',
              meta: { showSaveButton: true },
              component: () => import('@core/pages/new-business-register/components/policy/page/NBRDetailPage.vue')
            },
            {
              path: 'product',
              name: 'Policy Information',
              meta: { showSaveButton: false },
              component: () => import('@core/pages/new-business-register/components/policy/page/ProductPage.vue')
            },
            {
              path: 'individuals',
              name: 'Policy Entities / Individuals',
              meta: { showSaveButton: false },
              component: () => import('@core/pages/new-business-register/components/policy/page/PolicyIndividualPage.vue')
            },
            {
              path: 'underwriting',
              name: 'Underwriting Details',
              meta: { policies: ['lifeinsurance', LOB_VALUES.LIFE], showSaveButton: true },
              component: () => import('@core/pages/new-business-register/components/policy/page/UnderwritingPage.vue')
            },
            {
              path: 'riders',
              name: 'Component Details',
              meta: { policies: ['lifeinsurance', LOB_VALUES.LIFE, 'employeebenefits', 'group-eb', 'gi-health-group'], showSaveButton: false },
              component: () => import('@core/pages/new-business-register/components/policy/page/RiderPage.vue')
            },
            {
              path: 'insured-interest',
              name: 'Insured Interest Details',
              meta: { policies: ['gi-motors', 'gi-travel', LOB_VALUES.GI_MOTOR, LOB_VALUES.GI_TRAVEL, LOB_VALUES.GI_HOME, 'gi-homecontents'], showSaveButton: true },
              component: () => import('@core/pages/new-business-register/components/policy/page/InsuredInterestDetailPage.vue')
            },
            {
              path: 'premiums',
              name: 'Premium Details',
              meta: { policies: ['gi-others', LOB_VALUES.GI_OTHERS, LOB_VALUES.GI_HOME, LOB_VALUES.GI_PERSONAL_ACCIDENT, LOB_VALUES.GI_COMMERCIAL, 'gi-clines', 'gi-plines', 'gi-health-individual', LOB_VALUES.GI_ACCIDENT_HEALTH, 'gi-motors', LOB_VALUES.GI_MOTOR, 'gi-travel', LOB_VALUES.GI_TRAVEL, 'gi-homecontents', 'employeebenefits', 'group-gi', 'group-eb', 'gi-health-group'] , showSaveButton: true},
              component: () => import('@core/pages/new-business-register/components/policy/page/PremiumDetailPage.vue')
            },
            // { // removed as no required in ssp-1762
            //   path: 'commission-paid',
            //   name: 'Commission Paid Details',
            //   meta: { policies: ['gi-others', 'gi-clines', 'gi-plines', 'gi-health-individual', 'gi-motors', 'gi-travel'] },
            //   component: () => import('@core/pages/new-business-register/components/policy/page/CommissionPaidPage.vue')
            // },
            {
              path: 'ilp',
              name: 'ILP Details',
              meta: { policies: ['lifeinsurance', LOB_VALUES.LIFE], showSaveButton: false },
              component: () => import('@core/pages/new-business-register/components/policy/page/ILPPage.vue')
            },
            {
              path: 'portfolio',
              name: 'Portfolio',
              meta: { policies: ['collectiveinvestmentscheme', LOB_VALUES.INVESTMENT], showSaveButton: false },
              component: () => import('@core/pages/new-business-register/components/policy/page/TransactionPage.vue')
            },
            {
              path: 'info-sheet',
              name: 'Info Sheet',
              meta: { policies: ['employeebenefits', 'group-gi', LOB_VALUES.GI_GROUP, 'group-eb', 'gi-health-group'], showSaveButton: false },
              component: () => import('@core/pages/new-business-register/components/policy/page/InfoSheetPage.vue')
            }
          ]
        },
        {
          path: 'submited-documents',
          name: 'Submitted Documents',
          meta: { showSaveButton: false },
          component: () => import('@core/pages/new-business-register/components/policy/page/SubmittedDocumentPage.vue')
        },
        {
          path: 'policy-intermediaries',
          name: ' Intermediaries',
          meta: { showSaveButton: true },
          component: () => import('@core/pages/new-business-register/components/policy/page/IntermediariesPage.vue')
        }
      ]
    }
  ]
})()
