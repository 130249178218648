import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import importantNotice from '@core/assets/svg/imp-notice.svg'
import importantNoticeActive from '@core/assets/svg/imp-notice-solid.svg'
import finances from '@core/assets/svg/finances.svg'
import financesActive from '@core/assets/svg/finances-solid.svg'
import kyc from '@core/assets/svg/kyc-no-fill.svg'
import kycActive from '@core/assets/svg/kyc-fill.svg'
import currentPlans from '@core/assets/svg/current-plans.svg'
import affordabilityActive from '@core/assets/svg/affordability-solid.svg'
import affordability from '@core/assets/svg/affordability.svg'
import currentPlansActive from '@core/assets/svg/current-plans-solid.svg'
import financeObjective from '@core/assets/svg/flag.svg'
import financeObjectiveActive from '@core/assets/svg/flag-solid.svg'
import recommendation from '@core/assets/svg/recommendation.svg'
import recommendationActive from '@core/assets/svg/recommendation-solid.svg'
import investment from '@core/assets/svg/investment.svg'
import investmentActive from '@core/assets/svg/investment-solid.svg'
import profile from '@core/assets/svg/client.svg'
import profileActive from '@core/assets/svg/profile-solid.svg'
import priorities from '@core/assets/svg/priorities.svg'
// import clientDeclaration from '@core/assets/svg/client-declaration.svg'
// import switching from '@core/assets/svg/switching.svg'
// import disclaimer from '@core/assets/svg/disclaimers.svg'
// import notification from '@core/assets/svg/financial-adviser.svg'
import incompleteIcon from '@core/assets/svg/incomplete.svg'
import completedIcon from '@core/assets/svg/completed.svg'
import beneficialOwnerIcon from '@core/assets/svg/appendix-upload.svg'
// import politicalIcon from '@core/assets/svg/political-icon.svg'
import clientDeclaration from '@core/assets/svg/disclaimers.svg'
import clientDeclarationActive from '@core/assets/svg/disclaimers-solid.svg'
// import AMLCheckIcon from '@core/assets/svg/AML-check.svg'
import clientSummary from '@core/assets/svg/heart-empty.svg'
import clientSummaryActive from '@core/assets/svg/heart-selected.svg'
import generateSignPdf from '@core/assets/svg/adviser-declaration.svg'
import generateSignPdfActive from '@core/assets/svg/adviser-declaration-solid.svg'
import uploadDocs from '@core/assets/svg/form-completion-status.svg'
import uploadDocsActive from '@core/assets/svg/form-completion-status-solid.svg'
import additionalForm from '@core/assets/svg/additional-form.svg'
import additionalFormActive from '@core/assets/svg/additional-form-solid.svg'
@Module({
  namespaced: true,
  name: 'KYCSectionStore'
})
export default class KYCSectionStore extends VuexModule {
  // Note: name field is mapped with validation. for name changes use label option
  sections: { name: string; label?: string; url?: string; completed?: boolean; allowed?: boolean; icon?: string; iconActive?: string;completedIcon?: string; incompleteIcon?: string; subs?: any[] }[] = [
    {
      name: 'Important Notices',
      url: 'important-notices',
      completed: false,
      allowed: true,
      icon: importantNotice,
      iconActive: importantNoticeActive,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Client Information',
      url: 'about-you',
      completed: false,
      icon: profile,
      iconActive: profileActive,
      subs: [
        { name: 'Personal Details', label: "Personal Information", url: 'personal-details', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Trusted Individual', label: "Trusted Individual", url: 'trusted-individual', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon, visible: false },
        { name: `Dependants`, label: "Dependants's Details" ,url: 'dependants', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
      ],
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Financial Objectives',
      url: 'financial-objectives',
      completed: false,
      icon: financeObjective,
      iconActive: financeObjectiveActive,
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Your Finances',
      label: 'Finances',
      url: 'your-finances',
      completed: false,
      icon: finances,
      iconActive: financesActive,
      subs: [
        { name: 'Inflow & Outflow', url: 'cash-flow', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Assets & Liabilities', url: 'net-worth', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        // { name: 'Budget', url: 'budget', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Current Plans',
      label: 'Current Plans',
      url: 'current-plans',
      completed: false,
      icon: currentPlans,
      iconActive: currentPlansActive,
      subs: [
        { name: 'Existing Investments', url: 'investment', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Existing Insurance', label: 'Existing Insurance', url: 'insurance', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        // { name: 'Budget', url: 'budget', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Risk Profile',
      url: 'investment-risk-profile',
      completed: false,
      icon: investment,
      iconActive: investmentActive,
      subs: [
        { name: 'Investment Risk Profile', label: 'Investment Risk Profile', url: 'risk-profile-assessment', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Customer Knowledge Assessment', label: 'CKA/CAR', url: 'customer-knowledge-assessment', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
      ],
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Needs Analysis',
      url: 'needs-analysis',
      completed: false,
      icon: kyc,
      iconActive: kycActive,
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },

    // {
    //   name: 'Your Priorities & Needs Analysis',
    //   url: 'needs-analysis-old',
    //   completed: false,
    //   icon: priorities,
    //   subs: [
    //     { name: 'Adjustments & Assumptions', url: 'adjustments-and-assumptions', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    //     { name: 'Identifying Your Priorities', url: 'identifying-your-priorities', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    //     { name: 'Needs Analysis Calculation', url: 'needs-analysis-calculation', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
    //   ],
    //   allowed: true,
    //   completedIcon: completedIcon,
    //   incompleteIcon: incompleteIcon
    // },

    // TODO: New modules to be created for below section in upcomming sprints
    {
      name: 'Affordability',
      url: 'affordability',
      completed: true,
      icon: affordability,
      iconActive: affordabilityActive,
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'Client Summary',
      url: 'client-summary',
      completed: false,
      icon: clientSummary,
      iconActive: clientSummaryActive,
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },
    {
      name: 'space' ,
      label: 'Product Recommendation',
    },
    {
      name: 'Our Recommendation',
      url: 'our-recommendation',
      label: 'Products',
      completed: false,
      icon: recommendation,
      iconActive: recommendationActive,
      subs: [
        { name: 'Insurance Products Recommendation', label: 'Insurance Products', url: 'insurance-product-recommendation', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon, visible: true },
        { name: 'For Investments - ILP/Sub funds', label: 'Investment Funds', url: 'ilp-sub-funds', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon, visible: true },
        { name: 'Basis of Recommendation', url: 'basis-of-recommendation', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
        { name: 'Replacement / Switching', label: 'Switching / Replacement of Policy', url: 'replacement-or-switching', completed: false, completedIcon: completedIcon, incompleteIcon: incompleteIcon }
      ],
      allowed: true,
      completedIcon: completedIcon,
      incompleteIcon: incompleteIcon
    },

    // TODO: New modules to be created for below section in upcomming sprints
    {
      name: 'space' ,
      label: 'Declarations & Documents'
    },
    // { name: 'Replacement / Switching', url: 'replacement-or-switching', completed: false, allowed: true, icon: switching, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'Disclaimers & Client\'s Checklist', url: 'disclaimers-and-client-checklist', completed: false, allowed: true, icon: disclaimer, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Client\'s Declaration', label: 'Declarations', url: 'client-declaration', completed: false, allowed: true,  icon: clientDeclaration, iconActive: clientDeclarationActive, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'Financial Adviser\'s Declaration', url: 'financial-consultant-declaration', completed: false, allowed: true, icon: notification, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'KYC Confirmation', url: 'kyc-confirmation', completed: false, allowed: true, icon: kyc, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'space' },
    // TODO: New module to be created
    { name: 'Additional Forms', label: 'Additional Forms', url: 'bop', completed: false, allowed: true, icon: additionalForm, iconActive: additionalFormActive, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'Politically Exposed Person Form', url: 'pep', completed: false, allowed: true, icon: politicalIcon, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    // { name: 'Client\'s AML Check', url: 'aml', completed: false, allowed: true, icon: AMLCheckIcon, completedIcon: completedIcon, incompleteIcon: incompleteIcon },

    // TODO: New modules to be created for below section in upcomming sprints
    {
      name: 'space' ,
      label: 'Review & Submit'
    },
    { name: 'Generate & Sign PDF',  label:'Generate & Sign PDF',  url: 'generate-sign-pdf', completed: false, allowed: true, icon: generateSignPdf, iconActive: generateSignPdfActive, completedIcon: completedIcon, incompleteIcon: incompleteIcon },
    { name: 'Appendix - Upload PDFs',  label:'Product Application & Upload Documents',  url: 'appendix', completed: true, allowed: true, icon: uploadDocs, iconActive: uploadDocsActive,completedIcon: completedIcon, incompleteIcon: incompleteIcon }
  ]

  get sectionsList () {
    return JSON.parse(JSON.stringify(this.sections));
  }


  @Mutation
  toggleHideTITab (value) {
    this.sections[1].subs[1].visible = !value
  }

  @Action
  updateHideTTTab (state: boolean) {
    this.context.commit('toggleHideTITab', state)
  }

  @Mutation
  toggleHideInsurenceMenu (value) {
    this.sections[10].subs[0].visible = value
  }

  @Action
  updateInsurenceMenu (state: boolean) {
    this.context.commit('toggleHideInsurenceMenu', state)
  }

  @Mutation
  toggleHideInvestmentMenu (value) {
    this.sections[10].subs[1].visible = value
  }

  @Action
  updateInvestmentMenu (state: boolean) {
    this.context.commit('toggleHideInvestmentMenu', state)
  }
}
