import { TypeBase } from '../TypeBase'

export default class RequestSupervisorCallbackStatus extends TypeBase {
  status:string =  ''
  constructor (type: 'client_0' | 'client_1' = 'client_0') {
    super(`${type}_callback`)
  }

  extractData (rawData: any) {
    return super.extractData(rawData[this.type])
  }

  convertToRawData() {
    const results = super.convertToRawData();
    return {
        [this.type]: {
            status: this.status // Only include the status without the prefix
        }
    };
}

}
